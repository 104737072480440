import { getRenderProgress } from "../api";
import { RenderProgress } from "../api/api_types";
import { logDebug } from "../bugsnag";

export const checkRenderProgress = async (jobId: string, updateProgress: (params: RenderProgress) => void) => {
  let a: RenderProgress | undefined;
  try {
    a = await getRenderProgress(jobId);
  } catch {
    // In case of error (eg. when the job does not exists)
    updateProgress({
      progress: 0,
      is_finished: true,
      error: 'UNEXPECTED_ERROR'
    }); 

    return;
  }

  updateProgress(a);  

  if (!a.is_finished) {
    if (!a.is_finished && a.error) {
      logDebug(`TODO: isFinished is false but error is true`);
    }

    setTimeout(() => {
      checkRenderProgress(jobId, updateProgress);
    }, 1000);
  }
}
