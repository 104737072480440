import React from 'react';
import logoSrc from '../../images/gpxoverlay-logo-normal.svg';
import howItWorksIllustrationSrc from '../../images/gpxoverlay-howitworks-illustration.min.png';
import { Tabs, List } from "flowbite-react";
import { BUILD_DATE } from '../utils/env';

function Header() {
  return (
    <header className="header">
      <div className="container">
        <img className="logo" src={logoSrc} alt="gpxoverlay logo" />
        <img className="illustration_image" src={howItWorksIllustrationSrc} alt="gpxoverlay logo" />
      </div>

      <div className="container">
        <Tabs aria-label="Tabs with icons" variant="underline" color="primary">
          <Tabs.Item title="Compatibility">
            <div className="text-gray-600 pb-3">
              Compatible with <b>Davinci Resolve, Adobe Premiere Pro</b> (or others that handle photo sequences).
            </div>
            <div className="text-gray-600 pb-3">
              Upload them as a <b>photo sequence</b> in your video editor program. Tutorials:<br />
            </div>

            <List className='pl-10'>
              <List.Item>
                <a className='text-blue-600' target='_blank' href="https://jayaretv.com/tips/import-image-sequence-in-davinci-resolve/" rel="noreferrer"> Davinci Resolve</a>
              </List.Item>
              <List.Item>
                <a className='text-blue-600' target='_blank' href="https://helpx.adobe.com/premiere-pro/using/importing-still-images.html#import_numbered_still_image_sequences_as_video_clips" rel="noreferrer"> Adobe Premiere Pro</a>
              </List.Item>
            </List>
          </Tabs.Item>
          <Tabs.Item title="Video tutorial">
            {/* eslint-disable-next-line  jsx-a11y/iframe-has-title */}
            <div className='youtube-embed-container'><iframe src='https://www.youtube.com/embed/SsJHPG5e5I4' frameBorder='0' allowFullScreen></iframe></div>
          </Tabs.Item>
          <Tabs.Item title="Do you need more? | Contact">
            <List>
              <List.Item>This tool is in continuous development.</List.Item>
              <List.Item>Last update: {BUILD_DATE}</List.Item>
              <List.Item>If you need more features or need help, <a href="https://instagram.com/gpxoverlay" className='text-blue-600' target='_blank' rel="noreferrer">text me here (Instagram: gpxoverlay)</a></List.Item>
              <List.Item>Changelog:<br/>
                <List className='pl-10'>
                  <List.Item>13.11.2024 Show local date in catalog name, add local date in frames</List.Item>
                  <List.Item>31.10.2024 Improved interface. Add step to select parts of the activity to reduce file size and make render more readable.</List.Item>
                  <List.Item>21.10.2024 Speed up processing by 6x; Remove FPS option</List.Item>
                  <List.Item>19.10.2024 Make the design prettier</List.Item>
                  <List.Item>16.10.2024 Allow to choose additional stats to render</List.Item>
                  <List.Item>12.10.2024 Allow to download previously generated overlays</List.Item>
                  <List.Item>07.10.2024 Fix grade and speed change after activity break. (First few seconds of activity)</List.Item>
                  <List.Item>27.09.2024 Add option to select miles or kilometers</List.Item>
                  <List.Item>24.09.2024 Reduce photo size for quick processing</List.Item>
                  <List.Item>23.09.2024 Improve stability. Should not crash while processing anymore</List.Item>
                  <List.Item>22.09.2024 Handle more edge cases of gpx file.</List.Item>
                  <List.Item>21.09.2024 Added interpolation to smoother speed on frames</List.Item>
                </List>
              </List.Item>
              {/* <List.Item>Buy me a coffee to support development:</List.Item> */}
            </List>
          </Tabs.Item>
        </Tabs>
      </div>
    </header>
  )
}

export default Header;
