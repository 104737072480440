import React, { useState, useEffect } from 'react';
import { FbUserRendersEntity } from '../../types';
import { getUserDoc } from '../../api';
import { Button, Label, Spinner, TextInput } from 'flowbite-react';
import { downloadFile } from '../../utils/downloadFile';
import { checkRenderProgress } from '../../utils/checkRenderProgress';
import { validateEmail } from '../../utils/validateEmail';
import { saveEmailToLocalStorage } from '../RenderDetailsForm/utils';

const RenderListElement = ({ userRender }: { userRender: FbUserRendersEntity }) => {
  const { renderZipPath, jobId } = userRender;
  const label = renderZipPath.replaceAll('renders/', '').replaceAll('renders/', '');

  // TODO: Create a hook: useProgress;
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (!jobId) return;
    checkRenderProgress(jobId, ({
      progress, is_finished, error
    }) => {
      setProgress(progress);
      setIsFinished(is_finished);
      setIsError(!!error ? true : false);
    });
  }, [jobId]);
    
  return (
    <li className='w-full h-10 mt-3 mb-3 flex justify-start items-center justify-between'>
      <span>
        {label} {'\n'}
      </span>

      {
        !isError && isFinished && (
          <Button color="gray" pill={true} size="sm" outline onClick={() => downloadFile(renderZipPath)}>
            Download
          </Button>
        )
      }
      {
        !isError && !isFinished && (
          <div>
            <Spinner aria-label="Spinner button example" />
            <span className="ml-3">Processing: {`${Math.round(progress * 100)}%`}</span>
          </div>
        )
      }
      {
        isError && <span className='text-yellow-500'>Processing error</span>
      }
    </li>
  )
}

function PreviousRendersSection() {
  const [userRenders, setUserRenders] = useState<FbUserRendersEntity[]>();
  const [localStorageEmail, setLocalStorageEmail] = useState<string>(localStorage.getItem('email') ?? '');
  const [email, setEmail] = useState<string>(localStorageEmail);
  const [isEmailCorrect, setIsEmailCorrect] = useState<boolean>(false);
  const [shouldShowComponent, setShouldShowComponent] = useState<boolean>(false);

  useEffect(() => {
    const isCorrect = validateEmail(email);
    setIsEmailCorrect(isCorrect);
  }, [email]);

  useEffect(() => {
    const exec = async () => {
      if (localStorageEmail) {
        const userDoc = await getUserDoc(localStorageEmail);
        console.log('userDoc', userDoc)
        setUserRenders(userDoc?.renders);
      }
    }
    exec();
  }, [localStorageEmail]);
  
  const onEmailChange = (a: any) => {
    const changedEmail = a.target.value as string;
    setEmail(changedEmail);
  }

  const onEmailButtonClick = () => {
    saveEmailToLocalStorage(email)
    setLocalStorageEmail(email);
  };

  const showComponent = () => {
    setShouldShowComponent(true)
  };

  return (
    <section className="mt-6 w-full">
      {
        !shouldShowComponent && (
          <button onClick={showComponent} style={{ "textDecoration": "underline", "color": "#121045" }}>
            {`>`} Your previous renders {email && `(${email})`} 
          </button>
        )
      }

      {
        shouldShowComponent && (
          <>
            <h1 className='text-2xl font-bold'>Your previous renders:</h1>
            {email && (<span className='text-xs'>({email})</span>)} 
            <ul className='w-full'>
              {
                userRenders?.map((userRenderEntity) => (
                  <RenderListElement userRender={userRenderEntity} />
                ))
              }
            </ul>
            {
              !localStorageEmail && (
                <div className='mt-4'>
                  <Label htmlFor='past_renders_email'>
                    Input your email to see and download your previous renders
                  </Label>

                  <div className='flex items-center justify-between mt-1'>
                    <TextInput
                      id="past_renders_email"
                      type="email"
                      placeholder="youraddress@gmail.com"
                      onChange={onEmailChange}
                      value={email}
                      className='w-full'
                    />

                    <Button className="w-80 ml-4" color="primary" outline type="submit" disabled={!isEmailCorrect} onClick={onEmailButtonClick}>
                      Check past renders
                    </Button>
                  </div>
                  {
                    email && !isEmailCorrect && (
                      <span className='text-yellow-500'>
                        Email is not valid.
                      </span>
                    )
                  }
                </div>
              )
            }   
          </>
        )
      }
    </section>
  )
}

export default PreviousRendersSection;
