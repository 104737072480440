import preval from 'preval.macro';

const noValueError = <T>(name:string, defaultValue: T): T => {
  new Error(`No env: ${name}`)
  return defaultValue;
};

export const IS_PROD = process.env.NODE_ENV === 'production';
export const API_HOST = process.env.REACT_APP_API_HOST;
export const BUILD_DATE:string = preval`module.exports = new Date().toLocaleString();`
export const BUCKET_URL = process.env.REACT_APP_BUCKET_URL;
export const BUGSNAG_API_KEY: string = process.env.REACT_APP_BUGSNAG_API_KEY || noValueError('REACT_APP_BUGSNAG_API_KEY', '');
