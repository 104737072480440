import React, { useState } from 'react';
import RenderDetailsForm from '../RenderDetailsForm';
import PreviousRendersSection from '../PreviousRendersSection';
import UploadGpxForm from '../UploadGpxForm';
import { GpxOptions } from '../../api/api_types';

function MainContent() {
  const [options, setOptions] = useState<GpxOptions>();

  return (
    <main className="main">
      <div className="container">
        <h1 className='text-2xl font-bold mb-4'>Generate your overlays:</h1>
        {
          options
          ? <RenderDetailsForm options={options} />
          : <UploadGpxForm setOptions={setOptions} /> 
        }
        <PreviousRendersSection />
      </div>
    </main>
  )
}

export default MainContent;
