import React from 'react';
import { Button, Checkbox, Label } from 'flowbite-react';
import { UseFormReturn } from "react-hook-form";
import { FormValues } from '../../types';
import { AvailableParts} from '../../api/api_types';

function formatDateTime({ startTimestamp, timezone }: { startTimestamp: string, timezone: string }) {
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    timeZone: timezone,
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const timeFormatter = new Intl.DateTimeFormat('en-US', {
    timeZone: timezone,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });

  return {
    formattedDate: dateFormatter.format(new Date(startTimestamp)),
    formattedStartTime: timeFormatter.format(new Date(startTimestamp)),
  }
}

function msToHHMMSS(ms: number) {
  const hours = Math.floor(ms / 3_600_000);
  const minutes = Math.floor((ms % 3_600_000) / 60_000);
  const seconds = Math.floor((ms % 60_000) / 1000);

  return `${hours}h ${minutes}m ${seconds}s`;
}


const GpxPartsCheckboxes = ({ routeParts, formHandler, timezone }: { routeParts: AvailableParts, formHandler: UseFormReturn<FormValues, any, undefined>, timezone: string }) => {
  return (
    <>
      <span>
        Activity date: <b>{formatDateTime({ startTimestamp: routeParts[0].startTimestamp, timezone },).formattedDate}</b>
      </span>
      {
        routeParts.map(({ startTimestamp, endTimestamp }, index) => {
          const { formattedStartTime } = formatDateTime({ startTimestamp, timezone });
          const duration = Number(new Date(endTimestamp)) - Number(new Date(startTimestamp));

          return (
            <Label className="flex items-center">
              <Checkbox className='mr-2' {...formHandler.register(`parts.${index}`)} />
              Movement detected at &nbsp;<b>{formattedStartTime}</b>; Duration:&nbsp;<b>{msToHHMMSS(duration)}</b>
            </Label>
          )
        })
      }
    </>
  );
};

const SelectPartsStep = ({
  parts,
  formHandler,
  setIsActivityPartsSelected,
  timezone
}:
  {
    parts: AvailableParts,
    formHandler: UseFormReturn<FormValues, any, undefined>,
    setIsActivityPartsSelected: React.Dispatch<React.SetStateAction<boolean>>,
    timezone: string
  }) => {
  const onButtonClick = () => {
    setIsActivityPartsSelected(true)
  };

  const isAtLeastOnePartSelected = formHandler.watch('parts').some((a) => a);

  return (
    <>
      <fieldset className="flex flex-col gap-4">
        <legend className='mb-2'>
          During activity you stopped a few times. Select the moments you want to render:
        </legend>

        <GpxPartsCheckboxes routeParts={parts} timezone={timezone} formHandler={formHandler} />
      </fieldset>

      <Button disabled={!isAtLeastOnePartSelected} onClick={onButtonClick}>Next</Button>
    </>
  )
}

export default SelectPartsStep;
