import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDqvWkaHnIH3lR5S5MPTCQJ77IDm-s2LXM",
  authDomain: "gpxoverlay-febdf.firebaseapp.com",
  projectId: "gpxoverlay-febdf",
  storageBucket: "gpxoverlay-febdf.appspot.com",
  messagingSenderId: "452072990148",
  appId: "1:452072990148:web:97330e7028bb0e1e82f091",
  measurementId: "G-6CTN5194X5"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseStorage = getStorage(firebaseApp, firebaseConfig.storageBucket);
export const firebaseDb = getFirestore(firebaseApp)
