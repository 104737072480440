import React from 'react';
import Bugsnag, {Event} from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'
import { BUGSNAG_API_KEY, IS_PROD } from './components/utils/env';

const ErrorBoundaryPlaceholder = ({ children }: { children: React.ReactNode }) => (<>{ children } </>);

if (IS_PROD) {
  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()]
  })
  BugsnagPerformance.start({ apiKey: BUGSNAG_API_KEY });
}

const BugSnagErrorBoundary = IS_PROD && Bugsnag.getPlugin('react')
  ?.createErrorBoundary(React)

export const ErrorBoundary = IS_PROD && BugSnagErrorBoundary
  ? BugSnagErrorBoundary
  : ErrorBoundaryPlaceholder;

export const logError = (err: Error, level: Event['severity'], details: Object):void => {
  Bugsnag.notify(err, (event) => {
    event.severity = level;
    event.addMetadata('details', details)
  })
}

export const logDebug = (message: string, details?: Object): void => {
  Bugsnag.notify(message, (event) => {
    event.severity = 'warning';
    if (details) {
      event.addMetadata('details', details)
    }
  })
}
