import { match as tsPatternMatch} from "ts-pattern";

const errorCodes = [
  'THE_FILE_IS_NOT_GPX',
  'THE_FILE_CONTAINS_TRACK_INSTEAD_OF_ACTIVITY',
  'THE_FILE_CONTAINS_NO_POINTS',
  'GPX_IS_NOT_VALID_NO_TIME',
  'UNEXPECTED_ERROR',
] as const;
export type ErrorCodes = (typeof errorCodes)[number];

export function getErrorLabel(errorCode: string) {
  // @ts-ignore
  return tsPatternMatch(errorCode)
    .with('THE_FILE_CONTAINS_TRACK_INSTEAD_OF_ACTIVITY', () => 'Your file contains a track instead of an activity. Try with different gpx file.')
    .with('THE_FILE_IS_NOT_GPX', () => 'Please, upload only .GPX file!')
    .with('GPX_IS_NOT_VALID_NO_TIME', () => 'The file does not contain time! Please, export the gpx file from a different source.')
    .otherwise(() => 'Error. Fixing in progress, try again tomorrow!')
}
