import React from 'react';
import { Button } from 'flowbite-react';
import { UseFormReturn } from "react-hook-form";
import RadioButtonGroup from './RadioButtonGroup';
import { FormValues } from '../../types';
import AdditionalStatsCheckbox from './AdditionalStatsCheckbox';
import { GpxOptions } from '../../api/api_types';

const ViewSettings = ({ formHandler, renderZipPath, options }: { formHandler: UseFormReturn<FormValues, any, undefined>, renderZipPath: string | undefined, options: GpxOptions }) => {
  return (
    <>
      <fieldset className="flex flex-col gap-4">
        <legend>
          Choose your video resolution:
        </legend>

        <RadioButtonGroup
          formHandler={formHandler}
          inputName="resolution"
          options={[
            // {name:'hd', label:'720p (HD)'},
            { name: 'fullhd', label: '1080p (FullHD)' },
            { name: '4k', label: '2160p (4k)' },
          ]}
        />
      </fieldset>

      <fieldset className="flex flex-col gap-4">
        <legend>
          Choose units:
        </legend>

        <RadioButtonGroup
          formHandler={formHandler}
          inputName="units"
          options={[
            { name: 'kilometers', label: 'Kilometers' },
            { name: 'miles', label: 'Miles' },
          ]}
        />
      </fieldset>

      <fieldset className="flex flex-col gap-4">
        <legend>
          Choose additional stats to show:
        </legend>

        {
          options.availableData.elevationGain && (<AdditionalStatsCheckbox statName="grade" formHandler={formHandler} />)
        }
        {
          options.availableData.power && (<AdditionalStatsCheckbox statName="power" formHandler={formHandler} />)
        }
        {
          options.availableData.hr && (<AdditionalStatsCheckbox statName="hr" formHandler={formHandler} />)
        }
        {
          options.availableData.cadence && (<AdditionalStatsCheckbox statName="cadence" formHandler={formHandler} />)
        }
        
      </fieldset>

      {
        !renderZipPath ? (
          <Button color="primary" type="submit" disabled={!formHandler.formState.isValid} className='mt-4'>
            Generate
          </Button>
        ) : <div className='mb-1'></div>
      }
    </>
  )
}
export default ViewSettings;
