import { getDownloadURL, ref } from "firebase/storage";
import { firebaseStorage } from "../firebase";
import { FbUserRendersEntity } from "../types";

export const downloadFile = async (renderZipPath: FbUserRendersEntity['renderZipPath']) => {
  if (!renderZipPath) return;

  const renderZipRef = ref(firebaseStorage, renderZipPath);

  const href = await getDownloadURL(renderZipRef);

  const link = document.createElement('a');
  link.href = href;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}
