import React from 'react';
import { Label, Checkbox } from 'flowbite-react';
import { UseFormReturn } from 'react-hook-form';

type AdditionalStats = 'speed' | 'grade' | 'power' | 'hr' | 'cadence';
type Labels = {
  [key in AdditionalStats]: string;
};

const labels:Labels = {
  speed: 'Speed',
  grade: 'Grade',
  power: 'Power',
  hr: 'Heart Rate',
  cadence: 'Cadence',
}

function AdditionalStatsCheckbox({ statName, formHandler }: { statName: AdditionalStats, formHandler: UseFormReturn<any>}) {
  return (
    <Label className="flex items-center">
      <Checkbox className='mr-2' {...formHandler.register(`show.${statName}`)} />
      {labels[statName]}
    </Label>
  )
}

export default AdditionalStatsCheckbox;
